<template>
  <div class="card-edit">
    <!-- 表单提交 -->
    <el-form :model="params" :rules="rules" ref="form-ref" label-width="140px">
      <!-- 卡券信息 -->
      <section class="card-item">
        <h1 class="card-title">卡券信息</h1>
        <div class="card-box">
          <el-form-item label="卡券名称" prop="cardName">
            <el-input v-model.trim="params.cardName" autocomplete="off" maxlength="16" placeholder="请填写卡券名称"
              clearable></el-input>
          </el-form-item>
          <el-form-item label="卡券样式" prop="background" class="long-content">
            <el-image :class="[
              params.background == item.value ? 'card-bg-active' : 'card-bg',
            ]" v-for="item in cardBgArr" :key="item.value" :src="item.url" @click="onCardBg(item)"></el-image>
          </el-form-item>
          <el-form-item label="卡券备注" prop="remark">
            <el-input type="textarea" :rows="3" v-model.trim="params.remark" autocomplete="off" maxlength="100"
              placeholder="请填写卡券备注"></el-input>
          </el-form-item>
        </div>
      </section>
      <!-- 卡券设置 -->
      <section class="card-item">
        <h1 class="card-title">卡券设置</h1>
        <div class="card-box">
          <el-form-item label="卡券类型" prop="cardType">
            <el-radio-group style="width: 100%; display: flex" v-model="params.cardType" @change="goChangeCardType">
              <el-radio-button label="1">次数卡</el-radio-button>
              <el-radio-button label="3">代金券</el-radio-button>
              <el-radio-button label="5">自定义券</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <template v-if="params.cardType == 1 || params.cardType == 5">
            <el-form-item label="可用次数" prop="cardTypeNum">
              <el-input v-model.trim="params.cardTypeNum" autocomplete="off" maxlength="10" placeholder="请填写可用次数"
                disabled>
                <template slot="append">次</template>
              </el-input>
            </el-form-item></template>
          <template v-if="params.cardType == 3">
            <el-form-item label="最低使用金额" prop="minUsePrice">
              <el-input v-model.trim="params.minUsePrice" autocomplete="off" maxlength="10" placeholder="请填写可用次数">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="抵扣金额" prop="deductionPrice">
              <el-input v-model.trim="params.deductionPrice" autocomplete="off" maxlength="10" placeholder="请填写抵扣金额">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </template>

          <el-form-item label="卡券价格" prop="preferentialPrice">
            <el-input v-model="params.preferentialPrice" type="number" autocomplete="off" maxlength="10"
              placeholder="请填写卡券价格">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="划线价格">
            <el-input v-model.trim="params.originalPrice" type="number" autocomplete="off" maxlength="10"
              placeholder="请填写划线价格">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="卡券库存" prop="enableStock">
            <el-radio v-model="params.enableStock" :label="false">不限</el-radio>
          </el-form-item>
        </div>
      </section>
      <!-- 使用限制 -->
      <section class="card-item">
        <h1 class="card-title">使用限制</h1>
        <div class="card-box">
          <el-form-item label="有效期限" prop="effType" class="long-content">
            <el-radio-group v-model="params.effType">
              <el-radio-button label="1">不限制</el-radio-button>
              <el-radio-button label="2">有效天数</el-radio-button>
              <el-radio-button label="3">有效日期</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="在购买后" prop="afterDayNum" v-if="params.effType == '2'">
            <el-input v-model.trim="params.afterDayNum" clearable autocomplete="off" maxlength="10" placeholder="请填写天数">
              <template slot="append">天内有效</template>
            </el-input>
          </el-form-item>
          <el-form-item label="有效日期" prop="endTime" v-if="params.effType == '3'">
            <el-date-picker v-model="dateToDate" type="daterange" align="left" unlink-panels range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" clearable
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="适用范围" prop="allShopFlag">
            <el-checkbox v-model="params.allShopFlag" @change="checkBoxChange">所有门店</el-checkbox>
          </el-form-item>
          <div v-show="!params.allShopFlag">
            <el-form-item label="选择门店" prop="serviceIds"  :rules="{
            required: !params.allShopFlag, message: '请选择适用门店', trigger: ['blur', 'change']
          }">
            <el-cascader v-model="serviceIdArr" :options="serviceList" :props="{ multiple: true, emitPath: false }"
              collapse-tags clearable placeholder="请选择适用门店" @change="shopChange"></el-cascader>
          </el-form-item>
          </div>
          <el-form-item label="预约限制" prop="enablePreLimit">
            <el-radio v-model="params.enablePreLimit" :label="false">可用,不限时长</el-radio>
          </el-form-item>
          <el-form-item label="续单限制" prop="enableRenewalLimit">
            <el-radio v-model="params.enableRenewalLimit" :label="false">可用,不限时长</el-radio>
          </el-form-item>
          <el-form-item label="适用时段" class="long-content">
            <ul class="card-date-ul">
              <li class="card-date-item" v-for="(item, index) in params.cardCouponSuitDtoList" :key="index">
                <div>
                  <span>可用日期：</span>
                  <el-radio-group v-model="item.suitType">
                    <el-radio-button v-for="i in cardDateArr" :key="i.value" :label="i.value">{{ i.label
                      }}</el-radio-button>
                  </el-radio-group>
                </div>
                <div class="mt15" style="margin-left: 70px" v-if="item.suitType == '7'">
                  <el-checkbox-group v-model="item.suitWeek">
                    <el-checkbox v-for="i in cardWeekArr" :key="i.value" :label="i.value">{{ i.label }}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="other" v-if="item.suitType == '7'">
                  <el-select v-model="item.holidayContainType" style="width: 120px">
                    <el-option label="不考虑" value="1"></el-option>
                    <el-option label="不包含" value="2"></el-option>
                    <el-option label="或者" value="3"></el-option>
                  </el-select>
                  <span class="append">法定节假日</span>
                  <el-select v-model="item.workdayContainType" style="width: 120px">
                    <el-option label="不考虑" value="1"></el-option>
                    <el-option label="不包含" value="2"></el-option>
                    <el-option label="或者" value="3"></el-option>
                  </el-select>
                  <span class="append">补班</span>
                </div>
                <div class="mt15">
                  <span>可用时间：</span>
                  <el-time-select placeholder="起始时间" v-model="item.suitStartTime" :editable="false" :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                    maxTime: params.suitEndTime,
                  }">
                  </el-time-select>
                  <span class="mr15 ml15">至</span>
                  <el-time-select placeholder="结束时间" v-model="item.suitEndTime" :editable="false" :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                  }">
                  </el-time-select>
                  <span class="ml15">最多任意连续小时数：</span>
                  <el-select v-model="item.continuousMaxHour" placeholder="请选择连续时常" clearable
                    :disabled="params.cardType == 3" style="width: 220px">
                    <el-option v-for="item in continuousMaxHourObj" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <span class="card-tips" v-if="item.suitType != '7'">{{
                  cardTipsObj[item.suitType]
                }}</span>
                <span class="card-tips" v-if="item.suitType == '7'">
                  <span>*该卡券</span>
                  <span v-if="item.suitWeek.length">{{ changeWeek(item.suitWeek) }},</span>
                  <span>{{
                    changeContain(
                      item.holidayContainType,
                      item.workdayContainType
                    )
                  }}</span>
                </span>
                <span class="card-tips">
                  <span v-if="
                    item.suitStartTime == '00:00' &&
                    item.suitEndTime == '00:00'
                  ">全天可用</span>
                  <span v-else>{{ item.suitStartTime }}-{{ item.suitEndTime }}可用</span>
                  <span v-if="
                    item.continuousMaxHour != 0 &&
                    item.continuousMaxHour != 24
                  ">{{ item.continuousMaxHour }}小时</span>
                </span>
              </li>
            </ul>
          </el-form-item>
        </div>
      </section>

      <!-- 保存 -->
      <section class="form-footer">
        <el-button type="primary" @click="onSubmit('form-ref')">保 存</el-button>
      </section>
    </el-form>
  </div>
</template>

<script>
import { rules } from "@/db/rules";
import { ObjToOpt } from "@/utils/utils";
import {
  getCardEdit,
  getCardServiceList,
  getCardDetail,
  goUpdateCard
} from "@/api/card/card";

import {
  cardBgArr,
  pickerOptions,
  cardDateArr,
  cardWeekArr,
  cardTipsObj,
  continuousMaxHourObj,
} from "@/db/objs";
export default {
  data() {
    return {
      roomName: '全部门店',
      // from表单
      rules,
      cardBgArr,
      pickerOptions,
      cardDateArr,
      cardWeekArr,
      cardTipsObj,
      continuousMaxHourObj,
      ObjToOpt,
      params: {
        cardName: "", //卡券名称
        background: "0", //卡券样式
        remark: "", //卡券备注
        cardType: "1", //卡券类型
        cardTypeNum: "1", //可用次数
        effType: "1", //有效期限
        afterDayNum: "", //有效天数
        startTime: "", //有效开始
        endTime: "", //有效结束
        allShopFlag: false, //是否全部门店
        //roomIds: "", //适用范围
        serviceIds: "", //适用范围
        enablePreLimit: false, //预约限制
        enableRenewalLimit: false, //续单限制
        cardCouponSuitDtoList: [
          {
            suitType: "1", //可用日期
            suitWeek: [], //可用星期
            holidayContainType: "1", //节假日包含类型
            workdayContainType: "1", //补班日包含类型
            suitStartTime: "00:00", //开始时间
            suitEndTime: "00:00", //结束时间
            continuousMaxHour: 0, //可用次数
          },
        ],

        minUsePrice: 0, // 最低使用金额
        deductionPrice: "", // 抵扣金额
      },
      dateToDate: [], //有效时段
      serviceIdArr: [],
      serviceList: [],

    };
  },
  watch: {
    dateToDate(val) {
      if (val) {
        this.params.startTime = val[0];
        this.params.endTime = val[1];
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
  },
  created() {
    // 如果有cardId 参数说明是编辑，没有说明是新增
    if (this.$route.query.cardId) {
      this.getCardDetail(this.$route.query.cardId);
    }
  },
  mounted() {
    this.getCardServiceList(); // 【请求】卡券房间列表
  },
  methods: {
    shopChange(e){
      if(e.length>0){
        this.params.serviceIds = e.toString();
      }else{
        this.params.serviceIds=''
      }
    },
    checkBoxChange(e){
      if(e){
        this.params.serviceIds=''
        this.serviceIdArr=[]
      }
    },
    // 【请求】编辑卡券
    goUpdateCard() {
      let data = this.params;
      goUpdateCard(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$router.push("/card/cardList");
        }
      });
    },
    // 查询卡券详情
    getCardDetail(cardId) {
      let params = {
        cardId,
      };
      getCardDetail(params).then((res) => {
        if (res.isSuccess == "yes") {
          let tempArr = res.data.useDateList;
          tempArr.forEach((item) => {
            item.suitWeek = item.suitWeek.split(",");
            item.holidayContainType = String(item.holidayContainType);
            item.workdayContainType = String(item.workdayContainType);
          });
          this.params = {
            cardId: res.data.cardId,
            cardName: res.data.cardName, //卡券名称
            background: res.data.background, //卡券样式
            remark: res.data.remark, //卡券备注
            cardType: res.data.cardType, //卡券类型
            cardTypeNum: res.data.cardTypeNum, //可用次数
            preferentialPrice: res.data.preferentialPrice, //卡券价格
            originalPrice: res.data.originalPrice, //划线价格
            enableStock: res.data.enableStock == 0 ? false : true, //卡券库存
            effType: "1", //有效期限
            afterDayNum: "", //有效天数
            startTime: "", //有效开始
            endTime: "", //有效结束
            allShopFlag: res.data.allShopFlag, //是否所有门店
            // roomIds: res.data.roomIds, //适用范围
            serviceIds: res.data.serviceIds?res.data.serviceIds.toString():'', //适用范围
            enablePreLimit: false, //预约限制
            enableRenewalLimit: false, //续单限制
            cardCouponSuitDtoList: tempArr,
            enableBuyLimit: res.data.enableBuyLimit == 0 ? false : true, //购买数量限制
            buyMaxNum: res.data.buyMaxNum, //购买数量限制
            minUsePrice: res.data.minUsePrice, // 最低使用金额
            deductionPrice: res.data.deductionPrice, // 抵扣金额
          };
          if(res.data.serviceIds){
            this.serviceIdArr = res.data.serviceIds;
          }else{
            this.serviceIdArr=[]
          }
          
        }
      });
    },
    // 【请求】卡券房间列表
    getCardServiceList() {
      let data = {
        merchantId: this.params.merchantId,
      };
      getCardServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },
    // 选择卡券类型
    goChangeCardType() {
      if (this.params.cardType == 3) {
        this.params.cardCouponSuitDtoList.forEach((item) => {
          item.continuousMaxHour = 0;
        });
      }
    },
    // 【转换】星期
    changeWeek(arr) {
      let a = [];
      let newArr = JSON.parse(JSON.stringify(arr)).sort((a, b) => {
        return a - b; // 按升序排
      });
      for (let i of newArr) {
        a.push(this.cardWeekArr[i - 1].label);
      }
      return a.join(",");
    },

    // 【转换】节假日
    changeContain(a, b) {
      if (a == 1 && b == 1) {
        return "";
      } else if (a == 1 && b == 2) {
        return "(不包含补班)，";
      } else if (a == 1 && b == 3) {
        return "补班，";
      } else if (a == 2 && b == 1) {
        return "（不包含法定节假日），";
      } else if (a == 2 && b == 2) {
        return "（不包含补班和法定节假日），";
      } else if (a == 2 && b == 3) {
        return "补班（不包含法定节假日），";
      } else if (a == 3 && b == 1) {
        return "法定节假日，";
      } else if (a == 3 && b == 2) {
        return "法定节假日（不包含补班），";
      } else if (a == 3 && b == 3) {
        return "法定节假日、补班，";
      }
    },


    // 【请求】新增卡券
    getCardEdit() {
      let data = this.params;
      getCardEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$router.push("/card/cardList");
        }
      });
    },

    // 【监听】卡券样式
    onCardBg(item) {
      this.params.background = item.value;
    },

    // 【监听】表单提交
    onSubmit(formName) {
      console.log(this.params);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(
            "请仔细检查卡券内容的准确性，是否确认保存卡券信息?",
            "提示",
            {
              confirmButtonText: "确定保存",
              cancelButtonText: "检查一下",
              type: "error",
            }
          ).then(() => {
            this.getCardEdit();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.card-item {
  border-bottom: 0.15rem solid #f0f2f5;
}

.card-title {
  padding: 0.15rem;
  border-bottom: 1px solid #eee;
}

.card-box {
  padding: 0.15rem;
}

.card-bg {
  width: 96px;
  height: 40px;
  display: inline-flex;
  margin-left: 0.05rem;
  cursor: pointer;
}

.card-bg-active {
  width: 96px;
  height: 40px;
  display: inline-flex;
  margin-left: 0.05rem;
  cursor: pointer;
  outline: 2px solid red;
  border-radius: 5px;
}

.card-date-ul {
  outline: 2px dashed #eee;
  padding: 0.15rem;
}

.card-tips {
  color: #999;
}

.other {
  margin-left: 0.7rem;
  display: flex;
  align-items: center;
}

.append {
  display: inline-flex;
  width: 0.8rem;
  color: #909399;
  background: #f5f7fa;
  justify-content: center;
  margin-right: 0.15rem;
  outline: 1px solid #dcdfe6;
  height: 0.38rem;
}

.form-footer {
  padding: 0.15rem;
  display: flex;
  justify-content: center;
}
</style>